








import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import VisualAnalysisTableHeader from './VisualAnalysisTableHeader.vue';
import VisualAnalysisTableBody from './VisualAnalysisTableBody.vue';

@Component({
  components: {
    VisualAnalysisTableHeader,
    VisualAnalysisTableBody,
  },
})
export default class VisualAnalysisTable extends Vue {}
