






import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import VisualAnalysisChartHeader from './VisualAnalysisChartHeader.vue';
import VisualAnalysisChartBody from './VisualAnalysisChartBody.vue';

@Component({
  components: {
    VisualAnalysisChartHeader,
    VisualAnalysisChartBody
  }
})
export default class VisualAnalysisChart extends Vue {
}
