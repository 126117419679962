










import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import EmptyAnalysisPlaceholder from '../EmptyAnalysisPlaceholder.vue';
import VisualAnalysisPageHeader from './VisualAnalysisPageHeader.vue';
import { analysisStores } from '@/store/poultry/analysis';
import VisualAnalysisChart from './VisualAnalysisChart/VisualAnalysisChart.vue';
import VisualAnalysisTable from './VisualAnalysisTable/VisualAnalysisTable.vue';

@Component({
  components: {
    VisualAnalysisPageHeader,
    VisualAnalysisChart,
    VisualAnalysisTable,
    EmptyAnalysisPlaceholder 
  }
})
export default class VisualAnalysis extends Vue {
  //TODO
  store = analysisStores.visual.list;

  created() {
    this.store.setVisualCreated(false);
  }

  get isVisualCreated() {
    return this.store.isCreated;
  }
}
