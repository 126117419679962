
















import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { analysisStores } from '@/store/poultry/analysis';
import dayjs, { Dayjs} from 'dayjs';
import { ScoreAnalysis } from '@/models/poultry/analysis.model';
import { setFormattedDate } from '@/utils/date.formatter';

@Component({
  components: {}
})

export default class VisualAnalysisTableBody extends Vue {
  store = analysisStores.visual.list;

  get visuals() {
    return this.store.sortedVisual;
  }

  getPublishedMonth(date: string) {
    return setFormattedDate(date);
  }
}
