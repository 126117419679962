





import { Component, Vue, Watch } from 'vue-property-decorator';
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { analysisStores } from '@/store/poultry/analysis/index';
import dayjs, { Dayjs } from 'dayjs';
import { AnalysisData, BarChartProperties } from '@/models/poultry/analysis.model';
import { phase3_scheme } from '@/utils/chart.color';
import { phase2_chart, phase3_chart } from '@/utils/chart.config';
import { LineChartProperties } from '@/models/poultry/analysis.model';

@Component({
  components: {},
})
export default class VisualAnalysisChartBody extends Vue {
  store = analysisStores.visual.list;
  analysisChart: Chart = new Chart('', {});
  chartKey = Math.random();
  chartColor = phase3_scheme;

  @Watch('store.chartStyle')
  updateGraph() {
    this.store.fetchAll();
  }

  get visuals(): AnalysisData[] {
    return this.store.visual;
  }

  get monthVisuals() {
    const startDate = this.store.startDate;
    const endDate = this.store.endDate;

    let dates: Dayjs[] = [];
    let dateObj = dayjs(startDate!);
    const endDateObj = dayjs(endDate!).startOf('month').endOf('day');

    while (dateObj.isBefore(endDateObj)) {
      dates.push(dateObj);
      dateObj = dateObj.add(1, 'month');
    }
    return dates;
  }

  get months(): string[] {
    return this.monthVisuals.map((dateObj) => dateObj.format('MMM YY'));
  }

  scores(visual: AnalysisData) {
    let scoresArr: number[] = [];
    let data = visual.data;

    data.forEach(data => {
      scoresArr.push(data.score);
    });

    return scoresArr;
  }

  get crudeScale() {
    const paddings = 28 * this.months.length;
    const bars = 20 * this.dataSets.length * this.months.length;
    const base = 240;
    return paddings + bars + base;
  }

  get dataSets() {
    if (this.store.chartStyle === 'horizontalBar') {
      const resultArr: BarChartProperties[] = [];
      this.visuals.forEach((visual, i) => {
        resultArr.push({
          label: visual.name,
          data: this.scores(visual),
          backgroundColor: this.chartColor[i % this.chartColor.length],
          barThickness: 19,
        });
      })

      return resultArr;
    } else {
      const resultArr: LineChartProperties[] = [];
      
      this.visuals.forEach((visual, i) => {
        resultArr.push({
          label: visual.name,
          data: this.scores(visual),
          backgroundColor: 'transparent',
          borderColor: this.chartColor[i % this.chartColor.length],
          pointBackgroundColor: this.chartColor[i % this.chartColor.length],
          pointBorderColor: this.chartColor[i % this.chartColor.length],
          pointBorderWidth: 1,
          borderWidth: 2,
        });
      })

      return resultArr;
    }
  }

  get chartData() {
    if (this.store.chartStyle === 'horizontalBar') {
      return {
        ...phase3_chart,
        data: {
          labels: this.months,
          datasets: this.dataSets,
        },
      };
    } else {
      return {
        ...phase2_chart,
        data: {
          labels: this.months,
          datasets: this.dataSets,
        },
      };
    }
  }

  createChart(chartId: any, chartData: any) {
    const ctx = document.getElementById(chartId) as HTMLCanvasElement;
    this.analysisChart = new Chart(ctx, {
      plugins: [ChartDataLabels],
      type: chartData.type,
      data: chartData.data,
      options: chartData.options,
    });
  }

  beforePrintHandler () {
    for (var id in (Chart as any).instances) {
      (Chart as any).instances[id].resize();
    }
  }

  mounted() {
    Chart.plugins.register(ChartDataLabels);
    this.createChart('analysisChart', this.chartData);
    if (window.matchMedia) {
      let mediaQueryList = window.matchMedia('print');
      mediaQueryList.addListener((mql) => {
        if (mql.matches) {
          this.analysisChart.resize();
        }
      });
    }

    window.onbeforeprint = this.beforePrintHandler
  }
}
